<template>
  <div v-if="customer" class="min-h-screen bg-white flex">
    <div class="mx-auto w-full">
      <div class="grid lg:grid-cols-2 grid-flow-col-dense lg:min-h-screen">
        <div
          class="relative bg-gray-900 col-start-1 lg:col-start-2 z-0 lg:py-24"
        >
          <div class="px-6 bg-gray-900 z-10 lg:static lg:pl-24 lg:pr-8">
            <div class="max-w-sm w-full mx-auto lg:max-w-md lg:ml-0 lg:mr-auto">
              <div class="pt-10 pb-6 lg:py-0">
                <img
                  class="h-6 block"
                  src="https://www.persat.com.ar/img/persat_white.png"
                  alt="Persat"
                />
                <h1 class="mt-8">
                  <span
                    class="block text-lg leading-6 text-yellow-600 font-semibold"
                  >
                    Suscripción a Persat
                  </span>
                  <span class="mt-2 block">
                    <span class="sr-only">Precio</span>
                    <span
                      class="text-4xl font-extrabold tracking-tight leading-none text-white"
                      >{{ customer.plan.amount }}&nbsp;{{
                        customer.plan.currency
                      }}</span
                    >
                    <span class="text-base leading-6 text-gray-400">
                      por mes
                      <span v-if="!isActive" class="block lg:hidden text-sm"
                        >A cobrar desde el
                        {{ formatDate(customer.plan.pausedUntil) }}.</span
                      ></span
                    >
                  </span>
                  <span
                    v-if="!isActive"
                    class="text-gray-400 block text-sm lg:hidden"
                    >Más un pago inicial de
                    {{ customer.plan.startupAmount }}&nbsp;{{
                      customer.plan.currency
                    }}, por única vez.</span
                  >
                </h1>
                <div class="mt-4 lg:mt-8">
                  <p class="text-base leading-6 text-gray-400 font-semibold">
                    {{ customer.plan.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="px-6 lg:mt-6 lg:pl-24 lg:pr-8">
            <div class="max-w-sm w-full mx-auto lg:max-w-md lg:ml-0 lg:mr-auto">
              <div class="pb-8 mt-10 hidden lg:block">
                <dl
                  class="rounded-lg bg-black bg-opacity-25 divide-y divide-gray-600 divide-opacity-25 text-base leading-6 font-semibold"
                >
                  <!-- <div class="p-6 flex justify-between text-white">
                    <dt>
                      Abono mensual
                      <p
                        class="text-sm leading-6 text-gray-400"
                        v-if="!isActive"
                      >
                        Primer cobro el
                        {{ formatDate(customer.plan.pausedUntil) }}
                      </p>
                    </dt>
                    <dd>
                      <span>
                        {{ customer.plan.amount }}&nbsp;{{
                          customer.plan.currency
                        }}</span
                      >
                    </dd>
                  </div> -->

                  <div
                    class="p-6 flex justify-between text-white"
                    v-if="!isActive"
                  >
                    <dt>
                      Cobro inicial
                      <p class="text-sm leading-6 text-gray-400">
                        Se debitará por única vez
                      </p>
                    </dt>
                    <dd>
                      <span
                        >{{ customer.plan.startupAmount }}&nbsp;{{
                          customer.plan.currency
                        }}</span
                      >
                    </dd>
                  </div>
                </dl>
                <p class="text-gray-600 text-sm mt-4">
                  Ante cualquier consulta sobre pagos, no dude en comunicarse
                  con el área de administración de Persat,
                  administracion@persat.io o llamando al +54 11 4504-5300.
                </p>

                <!-- <pre class="text-gray-600 text-sm mt-4"
                  >{{ customer }}
                </pre> -->
              </div>
            </div>
          </div>
        </div>

        <div
          class="relative bg-white px-6 pt-6 pb-28 lg:py-24 col-start-1 lg:pl-8 lg:pr-24"
        >
          <div class="max-w-sm w-full mx-auto lg:mr-0 lg:ml-auto">
            <ul class="hidden lg:flex items-center space-x-2" v-if="!isActive">
              <li class="text-sm text-gray-500">
                <a
                  @click="(step = 1), (this.submitError = null)"
                  href="#"
                  class="font-medium text-gray-500"
                  :class="{
                    'text-gray-900': step === 1,
                    'hover:text-gray-700': step === 2,
                  }"
                  >Información personal</a
                >
              </li>
              <li>
                <svg
                  class="h-5 w-5 text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </li>
              <li
                class="text-gray-500 font-medium text-sm"
                :class="{ 'text-gray-900': step === 2 }"
              >
                Detalles de pago
              </li>
            </ul>

            <div class="space-y-2 lg:mt-7" v-if="!isActive">
              <div class="flex items-center justify-between">
                <h2 class="text-black font-semibold text-lg">
                  {{ step === 1 ? "Información personal" : "Detalles de pago" }}
                </h2>
                <span
                  class="lg:hidden text-gray-600 font-medium text-sm tabular-nums"
                  >Paso {{ step }} of 2</span
                >
              </div>
              <p class="text-gray-600 text-sm">
                {{
                  step === 1
                    ? "Complete sus datos de contacto."
                    : "Ingrese los datos de la tarjeta donde debitar el abono."
                }}
              </p>
            </div>

            <div class="mt-2 lg:mb-0">
              <div class="flow-root">
                <div v-if="!isActive">
                  <transition
                    :name="step === 1 ? 'fade-right' : 'fade-left'"
                    mode="out-in"
                  >
                    <form @submit.prevent="submitStep1" v-if="step === 1">
                      <main
                        class="max-w-7xl mx-auto py-6 grid grid-cols-6 gap-4"
                      >
                        <div class="col-span-6">
                          <BaseInput
                            id="name"
                            v-model.trim="customer.contact.name"
                            label="Nombre y apellido"
                            placeholder="Lionel Messi"
                            @blur="v$.customer.contact.name.$touch"
                            :disabled="loading"
                            :error="
                              v$.customer.contact.name.$errors[0]?.$message
                            "
                          />
                        </div>

                        <div class="col-span-6">
                          <BaseInput
                            id="email"
                            v-model.trim="customer.contact.email"
                            label="Correo electrónico"
                            placeholder="lio.messi@fcbarcelona.es"
                            @blur="v$.customer.contact.email.$touch"
                            :disabled="loading"
                            :error="
                              v$.customer.contact.email.$errors[0]?.$message
                            "
                          />
                        </div>

                        <div class="col-span-6">
                          <BaseInput
                            id="phone"
                            v-model.trim="customer.contact.phone"
                            label="Teléfono"
                            placeholder="Fijo o celular"
                            @blur="v$.customer.contact.phone.$touch"
                            :disabled="loading"
                            :error="
                              v$.customer.contact.phone.$errors[0]?.$message
                            "
                          />
                        </div>

                        <div class="col-span-6">
                          <InputSelect
                            label="Número de documento"
                            id="document"
                            :placeholder="docPlaceholder"
                            v-model.trim="customer.contact.docNumber"
                            v-model:option="customer.contact.docType"
                            @selectChange="
                              this.v$.customer.contact.docNumber.$reset
                            "
                            :options="docTypes"
                            @blur="v$.customer.contact.docNumber.$touch"
                            :disabled="loading"
                            :error="
                              v$.customer.contact.docNumber.$errors[0]?.$message
                            "
                          />
                        </div>

                        <div class="col-span-6 mt-4">
                          <button
                            type="submit"
                            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-100 ease-in"
                            :disabled="loading"
                          >
                            <span v-if="loading">
                              <svg
                                class="animate-spin h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  class="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  stroke-width="4"
                                ></circle>
                                <path
                                  class="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </span>

                            <span v-else>
                              Siguiente
                              <span
                                class="absolute right-0 inset-y-0 flex items-center pr-3"
                              >
                                <!-- Heroicon name: solid/chevron-right -->
                                <svg
                                  class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition duration-100 ease-in"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                      </main>
                    </form>

                    <form @submit.prevent="saveAndTokenize" v-else>
                      <main
                        class="max-w-7xl mx-auto py-6 grid grid-cols-6 gap-4"
                      >
                        <div class="col-span-6">
                          <CardDetails
                            v-model:number="cardnumber"
                            v-model:cvv="cvv"
                            v-model:expiration="expiration"
                            @numberBlur="v$.cardnumber.$touch"
                            :numberError="v$.cardnumber.$errors[0]?.$message"
                            @cvvBlur="v$.cvv.$touch"
                            :cvvError="v$.cvv.$errors[0]?.$message"
                            :expirationError="
                              v$.expiration.$errors[0]?.$message
                            "
                            :disabled="loading"
                            @expirationBlur="v$.expiration.$touch"
                          />
                        </div>

                        <div class="col-span-6">
                          <BaseInput
                            id="name"
                            v-model.trim="customer.card.holder"
                            label="Nombre del titular"
                            placeholder="Tal como figura en la tarjeta"
                            @blur="v$.customer.card.holder.$touch"
                            :disabled="loading"
                            :error="
                              v$.customer.card.holder.$errors[0]?.$message
                            "
                          />
                        </div>

                        <div class="col-span-6">
                          <fieldset>
                            <legend
                              class="block text-sm font-medium text-gray-700"
                            >
                              Dirección de facturación
                            </legend>
                            <div
                              class="mt-1 bg-white rounded-md shadow-sm -space-y-px"
                            >
                              <div>
                                <label for="card-number" class="sr-only"
                                  >Dirección (calle)</label
                                >
                                <BaseSimpleInput
                                  type="text"
                                  name="street"
                                  id="street"
                                  class="rounded-none rounded-t-md"
                                  placeholder="Dirección (calle)"
                                  v-model.trim="customer.address.street"
                                  @blur="v$.customer.address.street.$touch"
                                  :disabled="loading"
                                  :error="
                                    v$.customer.address.street.$errors[0]
                                      ?.$message
                                  "
                                />
                              </div>
                              <div class="flex -space-x-px">
                                <div class="w-1/2 flex-1 min-w-0">
                                  <label
                                    for="card-expiration-date"
                                    class="sr-only"
                                    >Número</label
                                  >
                                  <BaseSimpleInput
                                    type="text"
                                    name="street-number"
                                    id="street-number"
                                    class="rounded-none"
                                    placeholder="Número"
                                    v-model.trim="customer.address.streetNumber"
                                    @blur="
                                      v$.customer.address.streetNumber.$touch
                                    "
                                    :disabled="loading"
                                    :error="
                                      v$.customer.address.streetNumber
                                        .$errors[0]?.$message
                                    "
                                  />
                                </div>
                                <div class="flex-1 min-w-0">
                                  <label for="card-cvc" class="sr-only"
                                    >Código postal</label
                                  >
                                  <BaseSimpleInput
                                    type="text"
                                    name="zipcode"
                                    id="zipcode"
                                    class="rounded-none"
                                    placeholder="Código postal"
                                    v-model.trim="customer.address.zipcode"
                                    @blur="v$.customer.address.zipcode.$touch"
                                    :disabled="loading"
                                    :error="
                                      v$.customer.address.zipcode.$errors[0]
                                        ?.$message
                                    "
                                  />
                                </div>
                              </div>
                              <div>
                                <label for="card-number" class="sr-only"
                                  >Ciudad</label
                                >
                                <BaseSimpleInput
                                  type="text"
                                  name="city"
                                  id="city"
                                  class="rounded-none"
                                  placeholder="Ciudad"
                                  v-model.trim="customer.address.city"
                                  @blur="v$.customer.address.city.$touch"
                                  :disabled="loading"
                                  :error="
                                    v$.customer.address.city.$errors[0]
                                      ?.$message
                                  "
                                />
                              </div>
                              <div>
                                <label for="country" class="sr-only"
                                  >Provincia o Estado</label
                                >
                                <BaseSimpleSelect
                                  id="country"
                                  name="country"
                                  class="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                                  :class="{
                                    'text-gray-400':
                                      customer.address.state === '',
                                  }"
                                  v-model="customer.address.state"
                                  @blur="v$.customer.address.state.$touch"
                                  :disabled="loading"
                                  :error="
                                    v$.customer.address.state.$errors[0]
                                      ?.$message
                                  "
                                  :options="[
                                    { code: '', name: 'Provincia o Estado' },
                                    ...states,
                                  ]"
                                />
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        <div class="col-span-6" v-if="submitError">
                          <ErrorAlert :error="submitError" />
                        </div>

                        <div class="col-span-6 mt-4">
                          <button
                            type="submit"
                            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-100 ease-in"
                            :disabled="loading"
                          >
                            <span v-if="loading">
                              <svg
                                class="animate-spin h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  class="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  stroke-width="4"
                                ></circle>
                                <path
                                  class="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </span>

                            <span v-else>
                              Confirmar
                              <span
                                class="absolute right-0 inset-y-0 flex items-center pr-3"
                              >
                                <!-- Heroicon name: solid/lock-closed -->
                                <svg
                                  class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition duration-100 ease-in"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                            </span>
                          </button>

                          <p class="text-gray-600 text-sm mt-2 text-center">
                            Al confirmar, autorizo a Persat SRL a realizar
                            débitos automáticos a esta tarjeta, según el plan
                            contratado.
                          </p>
                        </div>
                      </main>
                    </form>
                  </transition>
                </div>

                <div v-if="isActive">
                  <h2
                    class="flex items-center relative text-xl leading-6 font-medium text-gray-900 pb-10"
                  >
                    La suscripción se encuentra activa
                  </h2>
                  <h3
                    class="text-lg leading-6 font-medium text-gray-900 pb-2 border-b border-gray-200"
                  >
                    Método de pago
                  </h3>
                  <div class="mt-5">
                    <div
                      class="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between"
                    >
                      <h4 class="sr-only">{{ customer.card.type }}</h4>
                      <div class="sm:flex sm:items-start">
                        <CardIcon :brand="customer.card.type" />
                        <div class="mt-3 sm:mt-0 sm:ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            {{ customer.card.type }} finalizada en
                            {{ customer.card.lastFour }}
                          </div>
                          <div
                            class="mt-1 text-sm text-gray-600 sm:flex sm:items-center"
                          >
                            <div>
                              Vence el
                              {{ formatDateTime(customer.card.dueDate) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <h3
                    class="mt-10 text-lg leading-6 font-medium text-gray-900 pb-2 border-b border-gray-200"
                  >
                    Historial de facturación
                  </h3> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <teleport to="#modal-container">
    <ModalSuccess :visible="showModal" @dismiss="closeModal">
      <template v-slot:title>La suscripción se realizó con éxito.</template>
      <template v-slot:message>
        Se registró un cobro inicial de
        {{ customer.plan.startupAmount }}&nbsp;{{
          customer.plan.currency
        }}.<br />Los cobros correspondientes al abono mensual de
        {{ customer.plan.amount }}&nbsp;{{ customer.plan.currency }} comenzarán
        el dia {{ formatDate(customer.plan.pausedUntil) }}.
      </template>
    </ModalSuccess>
  </teleport>
</template>

<script>
import Bugsnag from '@bugsnag/js'
import { formatISO } from "date-fns";
import CustomerService from "@/services/CustomerService";
import FunctionsService from "@/services/FunctionsService";
import { useDocTypes, useCountries, useStates } from "@/utils";
import useVuelidate from "@vuelidate/core";
import { formatDate, formatDateTime } from "@/composables/formatters";
import { helpers } from "@vuelidate/validators";
import {
  required,
  validateCardNumber,
  validateCardExpiration,
  email,
} from "@/composables/validators";
import CardDetails from "@/components/CardDetails";
import validateCard from "card-validator";
import ModalSuccess from "@/components/ModalSuccess.vue";
import CardIcon from "@/components/CardIcon.vue";
import NProgress from "nprogress";

const EBANX = window.EBANX;

export default {
  components: { CardDetails, ModalSuccess, CardIcon },

  setup() {
    return {
      v$: useVuelidate(),
      formatDate,
      formatDateTime,
    };
  },

  data() {
    return {
      customer: null,
      cardnumber: "",
      cvv: "",
      expiration: "",
      // invalidCard: false,
      states: [],
      countries: useCountries(),
      step: 1,
      loading: false,
      submitError: null,
      showModal: false,
    };
  },

  validations() {
    const validDocument = helpers.withMessage(
      "El documento indicado es inválido",
      helpers.withAsync(async () => {
        if (
          [
            "ar_cdi",
            "ar_cuil",
            "ar_cuit",
            "ar_dni",
            "br_cpf",
            "br_cnpj",
            "cl_rut",
            "co_cc",
            "co_ce",
            "co_nit",
            "ec_doc",
            "pe_doc",
            "uy_ci",
          ].includes(this.customer.contact.docType)
        ) {
          const docValidation = await EBANX.utils.document.check({
            type: this.customer.contact.docType,
            document: this.customer.contact.docNumber,
          });

          if (
            docValidation.status !== "success" ||
            !docValidation.data.document.isValid
          ) {
            return false;
          }

          this.customer.contact.docNumber =
            docValidation.data.document.maskedField;
        }

        return true;
      })
    );

    const validateCardCvv = helpers.withMessage(
      () => `La código de seguridad es inválido`,
      (value) => {
        let cvvLength = 4;
        if (this.cardnumber) {
          const cleanedNumber = this.cardnumber.replaceAll(/[^\d]/g, "");
          const cardValidation = validateCard.number(cleanedNumber);
          cvvLength = cardValidation.card?.code?.size || 4;
        }

        const validation = validateCard.cvv(value, cvvLength);
        return validation.isValid;
      }
    );

    return {
      cardnumber: { required, validateCardNumber },
      cvv: { required, validateCardCvv },
      expiration: { required, validateCardExpiration },
      customer: {
        contact: {
          name: { required },
          email: { required, email },
          phone: { required },
          docType: { required },
          docNumber: { required, validDocument },
        },
        address: {
          state: { required },
          city: { required },
          street: { required },
          streetNumber: { required },
          zipcode: { required },
        },
        card: {
          holder: { required },
          //dueDate: { required, validateCardExpiration },
        },
      },
    };
  },

  computed: {
    docTypes() {
      return useDocTypes(this.customer.country);
    },
    isActive() {
      return this.customer.plan.status === "active";
    },
    docPlaceholder() {
      // console.log(this.customer.contact.docType);
      const docTypeInfo = useDocTypes(this.customer.country).find(
        (doc) => doc.code === this.customer.contact.docType
      );
      // console.log(docTypeInfo)
      return docTypeInfo?.placeholder;
    },
  },

  watch: {
    customer: {
      handler() {
        // actualiza listado de states
        if (this.customer.country === "co") {
          this.states = useStates("co");
        } else {
          EBANX.referenceData
            .states({ country: this.customer.country })
            .get()
            .then((statesList) => {
              this.states = statesList.stateList.map((st) => {
                return { code: st.stateCode, name: st.stateName };
              });
            });
        }
      },
      deep: true,
    },
  },

  methods: {
    loadCustomer() {
      NProgress.start();
      CustomerService.loadCustomer(this.$route.params.id).then((result) => {
        this.customer = result;
        NProgress.done();

        EBANX.init({
          publicIntegrationKey: process.env.VUE_APP_EBANX_PK,
          mode: process.env.VUE_APP_EBANX_MODE,
          country: this.customer.country,
        });

        EBANX.deviceFingerprint.getSession().then((session) => {
          this.customer.security.deviceId = session.device_id;
        });
      });
    },

    async submitStep1() {
      this.loading = true;
      try {
        this.v$.customer.contact.$touch();
        if (this.v$.customer.contact.$invalid) return;

        NProgress.start();
        await CustomerService.saveCustomer(this.customer);

        // console.log("saveCustomer ok");
        this.step = 2;
      } finally {
        this.loading = false;
        NProgress.done();
      }
    },

    async saveAndTokenize() {
      this.loading = true;
      this.submitError = null;

      try {
        this.v$.$touch();
        if (this.v$.$invalid) return;

        // Guardo datos del cliente antes de la tokenización
        NProgress.start();
        await CustomerService.saveCustomer(this.customer);

        // Tokenización de la tarjeta
        const expValidation = validateCard.expirationDate(this.expiration);
        const exp = `${expValidation.month}/20${expValidation.year}`;
        const tokenizeOptions = {
          card: {
            number: this.cardnumber,
            cvv: this.cvv,
            dueDate: exp,
            holderName: this.customer.card.holder,
          },
          paymentTypeCode: "creditcard",
          countryCode: this.customer.country,
        };
        const tokenizedCard = await EBANX.cardTokenizer.tokenize(
          tokenizeOptions
        );
        this.customer.card.token = tokenizedCard;
        this.customer.card.lastFour = this.cardnumber.substr(
          this.cardnumber.length - 4
        );
        this.customer.card.dueDate = formatISO(
          new Date("20" + expValidation.year, expValidation.month, 1, 10)
        );
        this.customer.card.updatedAt = formatISO(new Date());
        this.customer.card.type = tokenizedCard.payment_type_code || "?";

        // Guardo datos de tokenización
        await CustomerService.saveCustomer(this.customer);
        // console.log("saveCustomer ok");

        // Inicio de la suscripción
        await FunctionsService.initCustomer(this.customer.id);
        // console.log("initCustomer ok");

        this.showModal = true;
      } catch (err) {
        this.submitError =
          "Ocurrió un error al intentar procesar el pago. Por favor revise que los datos ingresados sean correctos.";
        console.error(err);
        Bugsnag.notify(err);
      } finally {
        this.loading = false;
        NProgress.done();
      }
    },
    closeModal() {
      this.showModal = false;
      this.loadCustomer();
    },
  },

  mounted() {
    this.loadCustomer();
  },
};
</script>

<style>
.fade-left-enter-active,
.fade-left-leave-active,
.fade-right-enter-active,
.fade-right-leave-active {
  transition: all 0.3s ease;
}

.fade-left-enter-from,
.fade-right-leave-to {
  opacity: 0;
  transform: translateX(100px);
}

.fade-left-leave-to,
.fade-right-enter-from {
  opacity: 0;
  transform: translateX(-100px);
}
</style>