<template>
  <fieldset>
    <legend class="block text-sm font-medium text-gray-700">
      Información de la tarjeta
    </legend>
    <div class="mt-1 bg-white rounded-md shadow-sm -space-y-px">
      <div class="relative">
        <label for="card-number" class="sr-only">Número de la tarjeta</label>
        <input
          id="card-number"
          type="text"
          name="card-number"
          inputmode="numeric"
          placeholder="Número de la tarjeta"
          autocomplete="cc-number"
          autocorrect="off"
          spellcheck="false"
          aria-label="Número de la tarjeta"
          class="relative block w-full rounded-none rounded-t-md bg-transparent focus:z-20 sm:text-sm"
          :aria-invalid="!!numberError"
          :class="{
            'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 z-10': numberError,
            'border-gray-300 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500': !numberError,
          }"
          :value="number"
          @input="emitNumber"
          @blur="$emit('numberBlur', $event)"
        />
        <transition
          leave-active-class="transition duration-100 ease-in-out"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0 transform translate-x-10"
          enter-active-class="transition duration-100 ease-in-out"
          enter-to-class="opacity-100"
          enter-from-class="opacity-0 transform translate-x-10"
        >
          <div
            v-if="numberError"
            class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
          >
            <HeroIcon icon="solid-exclamation-circle" />
          </div>
        </transition>
      </div>

      <div class="flex -space-x-px">
        <div class="w-1/2 flex-1 min-w-0 relative">
          <label for="card-expiration-date" class="sr-only"
            >Fecha de vencimiento</label
          >
          <input
            id="card-expiration-date"
            type="text"
            name="card-expiration-date"
            inputmode="numeric"
            placeholder="MM / AA"
            autocomplete="cc-exp"
            autocorrect="off"
            spellcheck="false"
            aria-label="Vencimiento"
            class="relative block w-full rounded-none rounded-bl-md bg-transparent focus:z-20 sm:text-sm"
            :aria-invalid="!!error"
            :class="{
              'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 z-10': expirationError,
              'border-gray-300 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500': !expirationError,
            }"
            :value="expiration"
            @input="emitExpiration"
            @blur="$emit('expirationBlur', $event)"
          />
          <transition
            leave-active-class="transition duration-100 ease-in-out"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0 transform translate-x-10"
            enter-active-class="transition duration-100 ease-in-out"
            enter-to-class="opacity-100"
            enter-from-class="opacity-0 transform translate-x-10"
          >
            <div
              v-if="expirationError"
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            >
              <HeroIcon icon="solid-exclamation-circle" />
            </div>
          </transition>
        </div>
        <div class="flex-1 min-w-0 relative">
          <label for="card-cvc" class="sr-only">CVC</label>
          <input
            id="card-cvv"
            type="text"
            placeholder="CVV"
            name="card-cvv"
            autocomplete="cc-csc"
            autocorrect="off"
            spellcheck="false"
            inputmode="numeric"
            aria-label="CVC"
            class="relative block w-full rounded-none rounded-br-md bg-transparent focus:z-20 sm:text-sm"
            :aria-invalid="!!error"
            :class="{
              'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 z-10': cvvError,
              'border-gray-300 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500': !cvvError,
            }"
            :value="cvv"
            @input="emitCVV"
            @blur="$emit('cvvBlur', $event)"
          />
          <transition
            leave-active-class="transition duration-100 ease-in-out"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0 transform translate-x-10"
            enter-active-class="transition duration-100 ease-in-out"
            enter-to-class="opacity-100"
            enter-from-class="opacity-0 transform translate-x-10"
          >
            <div
              v-if="cvvError"
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            >
              <HeroIcon icon="solid-exclamation-circle" />
            </div>
          </transition>
        </div>
      </div>
    </div>
    <transition
      leave-active-class="transition duration-100 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0 transform translate-x-10"
      enter-active-class="transition duration-100 ease-in-out"
      enter-to-class="opacity-100"
      enter-from-class="opacity-0 transform translate-x-10"
      mode="out-in"
    >
      <BaseInputError v-if="error" :message="error" />
    </transition>
    <div class="hidden">
      <pre class="text-xs">CARD: {{ cardValidation }}</pre>
      <pre class="text-xs">EXP: {{ dateValidation }}</pre>
      <pre class="text-xs">CVV: {{ cvvValidation }}</pre>
    </div>
  </fieldset>
</template>

<script>
import BaseInputError from "@/components/BaseInputError";
import validateCard from "card-validator";

export default {
  components: { BaseInputError },
  props: {
    number: { type: String },
    expiration: { type: String },
    cvv: { type: String },
    numberError: { type: String },
    expirationError: { type: String },
    cvvError: { type: String },
  },
  data() {
    return { cardValidation: null, dateValidation: null, cvvValidation: null };
  },

  emits: [
    "update:number",
    "update:expiration",
    "update:cvv",
    "numberBlur",
    "expirationBlur",
    "cvvBlur",
  ],

  computed: {
    error() {
      return this.numberError || this.expirationError || this.cvvError;
    },
  },

  methods: {
    emitNumber(e) {
      let cleanedNumber = e.target.value.replaceAll(/[^\d]/g, "");

      const cardValidation = validateCard.number(cleanedNumber);
      this.cardValidation = cardValidation;

      if (cardValidation.card && cardValidation.card.gaps) {
        let formattedNumber = "";
        const gaps = cardValidation.card.gaps;

        cleanedNumber = cleanedNumber.substr(
          0,
          Math.max(...cardValidation.card.lengths)
        );

        for (let c = 0; c < cleanedNumber.length; c++) {
          formattedNumber += cleanedNumber.charAt(c);
          if (gaps.includes(c + 1)) formattedNumber += " ";
        }
        formattedNumber = formattedNumber.trim();

        this.$emit("update:number", formattedNumber);
      } else {
        this.$emit("update:number", cleanedNumber);
      }
    },
    emitExpiration(e) {
      let cleanedExp = e.target.value.replaceAll(/[^\d]/g, "");

      if (!["insertText", "insertFromPaste"].includes(e.inputType)) {
        this.$emit("update:expiration", e.target.value);
        return;
      }

      this.dateValidation = validateCard.expirationDate(cleanedExp);

      if (cleanedExp.substr(0, 2) === "00") {
        cleanedExp = "0";
      }

      if (cleanedExp.substr(0, 2) > 12 || cleanedExp.substr(0, 1) > 1) {
        cleanedExp = "0" + cleanedExp;
      }

      cleanedExp = cleanedExp.substr(0, 4);

      if (cleanedExp.length > 1) {
        cleanedExp = cleanedExp.substr(0, 2) + " / " + cleanedExp.substr(2, 2);
      }

      this.$emit("update:expiration", cleanedExp);
    },
    emitCVV(e) {
      let cleanedCVV = e.target.value.replaceAll(/[^\d]/g, "");

      this.cvvValidation = validateCard.cvv(cleanedCVV);

      const maxCVVLength = this.cardValidation?.card?.code?.size || 4;
      cleanedCVV = cleanedCVV.substr(0, maxCVVLength);

      this.$emit("update:cvv", cleanedCVV);
    },
  },
};
</script>

<style>
</style>