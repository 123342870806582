import { format } from "date-fns";
import { es } from "date-fns/locale";


const formatDate = (date) => {
    // Le sumo unas horas para que no tome el dia anterior por el huso horario
    return format(new Date(date + "T10:00"), "PPP", { locale: es });
}

const formatDateTime = (date) => {
    // Le sumo unas horas para que no tome el dia anterior por el huso horario
    return format(new Date(date), "PPP", { locale: es });
}

export {
    formatDate, formatDateTime
}
